<template>
  <div class="info">
    <a-row>
      <a-col :span="16" :offset="4">
        <div class="left">
          <div class="header">
            <div class="avatar" @click="$refs.resource.open(1, 'avatar')">
              <img v-if="!modelRef.avatar" src="@/assets/add.png" alt="" />
              <img v-else :src="modelRef.avatar" :alt="modelRef.name" />
            </div>
          </div>
          <div class="nickname">
            {{ user.username }}
          </div>
          <a-spin tip="Loading..." :spinning="loading">
            <a-form>
              <a-row>
                <a-col :span="5">
                  <div class="label-title">姓名</div>
                </a-col>
                <a-col :span="16">
                  <a-form-item>
                    <a-input v-model:value="modelRef.name" />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="5">
                  <div class="label-title">手机号</div>
                </a-col>
                <a-col :span="16">
                  <a-form-item>
                    <a-input v-model:value="modelRef.mobile" />
                  </a-form-item>
                </a-col>
              </a-row>

              <a-row>
                <a-col :span="5">
                  <div class="label-title">年龄</div>
                </a-col>
                <a-col :span="16">
                  <a-form-item>
                    <a-input-number v-model:value="modelRef.age" />
                  </a-form-item>
                </a-col>
              </a-row>

              <a-row>
                <a-col :span="5">
                  <div class="label-title">性别</div>
                </a-col>
                <a-col :span="16">
                  <a-form-item>
                    <a-radio-group name="sex" v-model:value="modelRef.sex">
                      <a-radio :value="0">未知</a-radio>
                      <a-radio :value="1">男</a-radio>
                      <a-radio :value="2">女</a-radio>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </a-spin>

          <div class="btns">
            <a-button
              block
              type="primary"
              @click="onSubmit((e) => onSubmitFun(e))"
              :loading="loading"
              style="width: 370px"
            >
              保存
            </a-button>
          </div>
        </div>
      </a-col>
    </a-row>

    <Resource ref="resource" @ok="emitChooseResource" />
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import { Form } from 'ant-design-vue'
import form from '@/mixins/form'
import { UserClass } from '@/apis/user'
import Resource from '@/components/Resource'

const useForm = Form.useForm
const api = new UserClass()
export default defineComponent({
  components: {
    Resource
  },
  mixins: [form],
  setup () {
    const loading = ref(false)
    const user = ref({})

    const modelRef = reactive(api.modelRef)
    const rulesRef = reactive(api.rulesRef)

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    return {
      loading,
      user,
      modelRef,
      resetFields,
      validate,
      validateInfos
    }
  },

  created () {
    this.user = this.$store.state.user.userInfo

    this.modelRef.name = this.user.name
    this.modelRef.mobile = this.user.mobile
    this.modelRef.age = this.user.age
    this.modelRef.avatar = this.user.avatar
    this.modelRef.sex = this.user.sex
    this.modelRef.address = this.user.address
    this.modelRef.id = this.user.id

    this.modelRef.pwd = '已加密'
    this.modelRef.uname = this.user.uname
  },

  methods: {
    onSubmitFun () {
      const data = toRaw(this.modelRef)
      api
        .update({
          name: data.name,
          mobile: data.mobile,
          age: data.age,
          avatar: data.avatar,
          sex: data.sex,
          address: data.address,
          uname: data.uname,
          pwd: data.pwd,
          id: data.id
        })
        .then(() => {
          this.loading = false
          this.$message.success('编辑成功')

          Object.assign(this.user, data)
          this.$store.commit('SET_INFO', this.user)
        })
        .catch(() => {
          this.loading = false
        })
    },

    emitChooseResource (e) {
      const image = e.images[0]
      this.modelRef.avatar = image
    }
  }
})
</script>

<style lang="less" scoped>
.info {
  padding: 10px;
  height: 100%;
  overflow: auto;
  .left {
    padding-top: 30px;
    .header {
      display: flex;
      justify-content: center;
      .avatar {
        width: 150px;
        height: 150px;
        border: 1px solid rgb(221, 220, 220);
        background: #f1f1f1;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .nickname {
      text-align: center;
      line-height: 30px;
      font-size: 16px;
      padding-top: 30px;
    }

    .btns {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
